import { QueryKey, UseQueryOptions } from "react-query";
import { TradingAccount } from "../api/types";

export interface StaticMap<K, V> extends Map<K, V> {
  get(key: K): V;
}

export type UseQueryOptionsUnknown = UseQueryOptions<
  unknown,
  unknown,
  unknown,
  QueryKey
>[];
export type UseQueryOptionsTradingAccount = UseQueryOptions<
  TradingAccount,
  unknown,
  unknown,
  QueryKey
>;

export enum StepStatus {
  Incomplete = 0,
  Started = 1,
  Completed = 2,
}
