import React from "react";

import {
  Center,
  Flex,
  Link,
  Tooltip,
  useClipboard,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import colors from "../theme/colors";

type UUIDProps = {
  onClick?: () => void;
  isTruncated?: boolean;
  value: string;
};

const UUID = ({ onClick, isTruncated, value }: UUIDProps): JSX.Element => {
  const { onCopy } = useClipboard(value);
  const toast = useToast();

  const copyToClipboard = () => {
    onCopy();
    toast({
      description: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const linkColor = useColorModeValue(colors.yellow, colors.yellow500);

  const truncatedProps = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "154px",
  };

  return (
    <Flex>
      <Center color={linkColor}>
        <Link
          onClick={onClick}
          color={linkColor}
          mr="0.5rem"
          // ensure uuid has consistent length if it's not truncated
          width={value.length === 40 ? "308px" : undefined}
          {...(isTruncated && truncatedProps)}
        >
          {value}
        </Link>
        {!!value.length && (
          <Tooltip label="Copy to clipboard">
            <CopyIcon cursor="pointer" onClick={copyToClipboard} />
          </Tooltip>
        )}
      </Center>
    </Flex>
  );
};

export default UUID;
