import moment from "moment";
import Header from "../components/layout/Header";
import Stat from "../components/general/Stat";
import styled from "styled-components";
import Table from "../components/general/Table";
import Card from "../components/general/Card";
import OmnibusDashboard from "./OmnibusDashboard";
import LineCard from "../components/general/LineCard";
import OrderDetails from "../components/page/OrderDetails";
import TransferDetails from "../components/page/TransferDetails";
import useWindowDimensions from "../globals/windowdim";

import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import {
  getAccounts,
  getCorrespondent,
  getOrders,
  getStats,
  getTransfers,
} from "../api/api";

import { useQuery } from "react-query";
import { APILinks } from "../globals/consts";
import { AppContext } from "../globals/appcontext";
import { useNavigate } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { Order, Stats, Transfer } from "../api/types";
import { getAmplitude, EventType } from "../globals/amplitude";
import { capitalize, ordersToTableRows, fmtLCTMoney } from "../globals/utils";
import { Box, Grid, GridItem, Text, useToast } from "@chakra-ui/react";

const BASE_CURRENCY = "USD";

const HeaderStat = styled(GridItem)`
  margin-left: 1rem;
  ${isBrowser ? "margin-top: 2rem;" : ""}
`;

const Dashboard = (): React.ReactElement => {
  const appContext = React.useContext(AppContext);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const [selectedTransfer, setSelectedTransfer] = useState<
    Transfer | undefined
  >();
  const [hideBanner, setHideBanner] = useState<boolean>(false);

  const toast = useToast();
  const navigate = useNavigate();
  const winSize = useWindowDimensions();

  const ordersQuery = useQuery("orders", () =>
    getOrders({ status: "all", limit: 100 })
  );
  const accountsQuery = useQuery("accounts", () => getAccounts());
  const transfersQuery = useQuery("transfers", () =>
    getTransfers({ limit: 100 })
  );
  const statsQuery = useQuery("stats", getStats);
  const corrQuery = useQuery("correspondent", getCorrespondent);
  const stats = statsQuery.data || ({} as Stats);
  const newAccounts = accountsQuery.data || [];

  // line chart values
  const chartValues = useMemo(() => {
    const accounts = stats.accounts_created_30d;
    const values = [];

    // fill gaps in dates
    if (accounts) {
      const start = moment().subtract(30, "days");
      while (start < moment()) {
        const date = start.format("YYYY-MM-DD");
        const value = (accounts[date] || 0) as number;
        values.push({ name: date, value });
        start.add(1, "days");
      }
    }

    return values;
  }, [stats]);

  const isError =
    ordersQuery.isError || transfersQuery.isError || accountsQuery.isError;
  useEffect(() => {
    const err =
      ordersQuery.error || transfersQuery.error || accountsQuery.error;
    // Only display error if appContext is valid
    if (isError && appContext?.correspondent?.ID)
      toast({
        title: "An error occurred fetching dashboard data",
        description: (err as Error).message,
        status: "error",
      });
  }, [isError]);

  useEffect(() => {
    getAmplitude().track({ event_type: EventType.NAVIGATED_DASHBOARD });
  }, []);

  // adjust page grid on resizing
  let pageCol, halfBox, cardH;
  switch (winSize) {
    case "mobile":
    case "small":
      pageCol = 6;
      halfBox = 6;
      cardH = "27.5rem";
      break;
    case "medium":
      pageCol = 8;
      halfBox = 4;
      cardH = "26.5rem";
      break;
    case "large":
      pageCol = 10;
      halfBox = 5;
      cardH = "25.5rem";
  }

  const tradingCurrency = corrQuery.data?.lct_currency ?? "USD";
  const orders = ordersToTableRows(ordersQuery.data, true, tradingCurrency);
  const transfers = transfersQuery.data || [];
  const colSpan = isBrowser ? 2 : 3;

  const showCompleteProfileNotif =
    !corrQuery?.data?.bd_data?.url &&
    hideBanner &&
    appContext.correspondent?.Env === "sandbox";

  if (appContext.correspondent.Setup === "omnibus") {
    return (
      <OmnibusDashboard
        cardHeight={cardH}
        pageCol={pageCol}
        halfBox={halfBox}
        ordersTableRows={orders}
        colSpan={colSpan}
      />
    );
  }

  const CartTitle = (props: PropsWithChildren<unknown>) => (
    <Text fontSize="xl" mb="1rem" fontWeight={700}>
      {props.children}
    </Text>
  );

  return (
    <Box>
      <TransferDetails
        isOpen={!!selectedTransfer}
        onClose={() => setSelectedTransfer(undefined)}
        transfer={selectedTransfer}
      />
      <OrderDetails
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        order={selectedOrder}
        currency={tradingCurrency}
      />
      <Header
        title="Welcome Back!"
        showCompleteProfile={showCompleteProfileNotif}
        hideBanner={setHideBanner}
      />
      <Grid templateColumns={`repeat(${pageCol}, 1fr)`} gap="2.5rem" mt="1rem">
        <HeaderStat colSpan={colSpan}>
          <Stat
            change={stats.active_accounts_change_last || 0}
            changeInfo="from last week"
            stat={stats.active_accounts}
            statInfo="# Active Accounts"
          />
        </HeaderStat>
        <HeaderStat colSpan={colSpan}>
          <Stat
            change={stats.orders_count_week_change || 0}
            changeInfo="from last week"
            stat={stats.orders_count_week}
            statInfo="# Orders this week"
          />
        </HeaderStat>
        <HeaderStat colSpan={winSize === "mobile" ? 6 : 2}>
          <Stat stat={stats.aum} isMoney statInfo="Assets Under Management" />
        </HeaderStat>
        <GridItem colSpan={halfBox}>
          <Card height={cardH}>
            <CartTitle>New Signups</CartTitle>
            <Table
              onRowClick={(rowIndex) =>
                navigate(`/accounts/${newAccounts[rowIndex].id}`)
              }
              isLoading={accountsQuery.isLoading}
              inCard
              headers={["Account ID", "Email", "Created At"]}
              copyIndexes={[0]}
              rowsPerPage={6}
              totalRows={newAccounts.length}
              rows={newAccounts.map((act) => [
                act.id,
                act.email || "",
                act.created_at,
              ])}
              noContentTitle="No new signups available"
              noContentLinkText="Learn more about Accounts"
              noContentLinkDestination={APILinks.accounts}
            />
          </Card>
        </GridItem>
        <GridItem colSpan={halfBox}>
          <LineCard
            height={cardH}
            title={<CartTitle>Signups</CartTitle>}
            values={chartValues}
            noContentTitle="No signups in the last 30 days"
            noContentLinkText="Learn more about Accounts"
            noContentLinkDestination={APILinks.accounts}
          />
        </GridItem>
        <GridItem colSpan={pageCol}>
          <Card height={cardH}>
            <CartTitle>Recent {!isMobile && "Funding"} Transactions</CartTitle>
            <Table
              isLoading={transfersQuery.isLoading}
              onValueClick={(rowIdx, colIdx) => {
                // Account ID on table was clicked
                if (colIdx === 0)
                  navigate(`/accounts/${transfers[rowIdx].account_id}`);
              }}
              inCard
              headers={[
                "Account ID",
                "Account Number",
                "Transaction ID",
                "Direction",
                "Amount",
                "Status",
                "Updated at",
              ]}
              copyIndexes={[0, 1]}
              rightJustifyIndexes={[3]}
              rowsPerPage={6}
              totalRows={transfers.length}
              onRowClick={(idx) => setSelectedTransfer(transfers[idx])}
              noContentTitle="No funding transactions available"
              noContentLinkText="Learn more about Funding"
              noContentLinkDestination={APILinks.funding}
              rows={transfers.map((t) => [
                t.account_id,
                t.account_number,
                t.id,
                capitalize(t.direction),
                fmtLCTMoney(t.amount, BASE_CURRENCY),
                t.status,
                t.updated_at,
              ])}
            />
          </Card>
        </GridItem>
        <GridItem colSpan={pageCol}>
          <Card height={cardH}>
            <CartTitle>Recent Orders</CartTitle>
            <Table
              isLoading={ordersQuery.isLoading}
              inCard
              headers={[
                "Account ID",
                "Order ID",
                "Side",
                "Symbol",
                "Quantity",
                "Total",
                "Status",
                "Submitted At",
                "Updated At",
                "Expires At",
              ]}
              copyIndexes={[0, 1]}
              rightJustifyIndexes={[4, 5]}
              rowsPerPage={6}
              rows={orders}
              totalRows={orders.length}
              onValueClick={(rowIdx, colIdx) => {
                // Account ID on table was clicked
                if (colIdx === 0) navigate(`/accounts/${orders[rowIdx][0]}`);
              }}
              onRowClick={(idx) =>
                setSelectedOrder((ordersQuery.data || [])[idx])
              }
              noContentTitle="No recent orders available"
              noContentLinkText="Learn more about Orders"
              noContentLinkDestination={APILinks.orders}
            />
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Dashboard;
