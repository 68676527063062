import React, { useEffect, useState } from "react";
import {
  Text,
  useClipboard,
  useToast,
  Tooltip,
  Skeleton,
  Stack,
  HStack,
  Box,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import {
  CheckIcon,
  ChevronLeftIcon,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { CopyIcon } from "@chakra-ui/icons";
import styled from "styled-components";
import colors from "../theme/colors";
import { BrokerAPIDocSrc } from "../../globals/consts";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { Scrollbox, hideScrollBar } from "./ScrollBox";
import NoContent from "./NoContent";

const TableContainer = styled(Scrollbox)`
  overflow-x: scroll;
  ${hideScrollBar};
`;

const RowItem = styled(Box)`
  padding: 0.75rem 1rem;
  font-weight: 500;
  ${(props) => (!props.$inCard ? "margin-bottom: 0.5rem; padding: 1rem;" : "")}
  ${(props) => (props.$active ? "background: rgba(0, 96, 170, 0.08);" : "")}
  ${(props) =>
    props.$lRadius && !props.$inCard
      ? "border-top-left-radius: 6px; border-bottom-left-radius: 6px;"
      : ""}
  ${(props) =>
    props.$rRadius && !props.$inCard
      ? "border-top-right-radius: 6px; border-bottom-right-radius: 6px;"
      : ""}
  ${(props) =>
    props.$canCopy
      ? `color: ${
          props.$colorMode === "dark" ? colors.yellow : colors.yellow500
        };`
      : ""}
  &:hover {
    cursor: pointer;
  }
`;

const PaginationPrev = styled(ChevronLeftIcon)`
  font-size: 22px;
  &:hover {
    cursor: pointer;
    color: ${colors.yellow};
  }
`;

const PaginationNext = styled(PaginationPrev)`
  transform: rotate(180deg);
`;

export type TableValue = string | moment.Moment;

interface TableProps {
  title?: string;
  headers: string[];
  rows: TableValue[][];
  copyIndexes?: number[];
  rightJustifyIndexes?: number[];
  rowsPerPage?: number;
  inCard?: boolean;
  onRowClick?: (rowIndex: number) => void;
  onValueClick?: (rowIndex: number, colIndex: number) => void;
  onDelete?: (rowIndex: number) => void;
  onDownload?: (rowIndex: number) => void;
  onPage?: (page: number) => void;
  isLoading?: boolean;
  buttonSkipClick?: boolean;
  totalRows?: number;
  loadMoreData?: () => void;
  disabled?: boolean;
  columnMaxWidths?: (number | null)[];

  // Values for no content display
  showEmpty?: boolean;
  noContentTitle?: string;
  noContentLinkText?: string;
  noContentLinkDestination?: string;
  halfCard?: boolean;
  hidePagination?: boolean;
}

// what words trigger text styling
const stylingKeys = {
  success: [
    "buy",
    "active",
    "filled",
    "fill",
    "complete",
    "new",
    "partially filled",
    "done for day",
    "incoming",
  ],
  warning: [
    "pending",
    "replaced",
    "pending cancel",
    "pending replaced",
    "action required",
    "approval pending",
  ],
  theme: ["email", "name"],
  info: ["submitted"],
  failed: [
    "sell",
    "cancelled",
    "inactive",
    "expired",
    "account closed",
    "disabled",
    "outgoing",
  ],
};

const iconStyling = {
  success: ["complete", "filled"],
  pending: ["pending", "held", "pending_cancel", "pending_new", "expired"],
  failed: ["failed", "canceled", "rejected"],
};

const Table = (props: TableProps): React.ReactElement => {
  const [itemHovered, setItemHovered] = useState([-1, -1]);
  const colorMode = useColorModeValue("light", "dark");
  const [startIndex, setStartIndex] = useState(0);

  let clipboardValue = "";
  try {
    const row = startIndex + itemHovered[0];
    clipboardValue = props.rows[row][itemHovered[1]].toString();
  } catch {}

  const [confirmDelete, setConfirmDelete] = useState(false);
  const { onCopy } = useClipboard(clipboardValue);
  const toast = useToast();

  // When new data loaded (usually filter update)
  useEffect(() => {
    // If current index would be out of bounds
    if (startIndex > props.rows.length - 1) setStartIndex(0);
  }, [props.isLoading]);

  if (props.isLoading) {
    const height = props.inCard ? "20px" : "40px";
    return (
      <Stack spacing={10} opacity="30%" mt="2rem">
        <Skeleton height={height} />
        <Skeleton height={height} />
        <Skeleton height={height} />
        <Skeleton height={height} />
        <Skeleton height={height} />
      </Stack>
    );
  }

  const copyValue = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCopy();
    toast({ description: "Value Copied", duration: 1500 });
  };

  // if table value can be copied
  const showCopyIcon = (i: number, j: number) => {
    return (
      i === itemHovered[0] &&
      j === itemHovered[1] &&
      props.copyIndexes?.includes(j)
    );
  };

  // indexing for pagination; default to 10 if not set
  const perPage = props.rowsPerPage || 10;
  const endIndex = startIndex + perPage;

  // style table value if styled props is set
  const itemColor = (value: string): string => {
    if (props.disabled) return colors.gray;
    if (stylingKeys.success.includes(value.toLowerCase())) return "success";
    if (stylingKeys.warning.includes(value.toLowerCase())) return "warning";
    if (stylingKeys.failed.includes(value.toLowerCase())) return "error";
    if (stylingKeys.info.includes(value.toLowerCase())) return "info";
    if (stylingKeys.theme.includes(value.toLowerCase())) return colors.yellow;
    return "";
  };

  const getEntryIcon = (value: string) => {
    if (iconStyling.success.includes(value.toLowerCase()))
      return <CheckIcon mr="4px" />;
    if (iconStyling.pending.includes(value.toLowerCase()))
      return <TimeIcon mr="4px" />;
    if (iconStyling.failed.includes(value.toLowerCase()))
      return <CloseIcon mr="4px" />;
    return null;
  };

  const clickRow = (i: number, j: number) => {
    if (props.onValueClick) props.onValueClick(i + startIndex, j);
    if (props.onRowClick) props.onRowClick(i + startIndex);
  };

  const rJustify = (index: number) =>
    props.rightJustifyIndexes?.includes(index);

  const formatValue = (value: string | moment.Moment, index: number) => {
    if (moment.isMoment(value)) {
      return (
        <>
          <Text mt="-5px">{value.format("YYYY-MM-DD")}</Text>
          <Text opacity="60%" mt="-7px" mb="-7px" fontSize="small">
            {value.format("hh:mm a")}
          </Text>
        </>
      );
    }

    if (rJustify(index)) {
      return <Box float="right">{value}</Box>;
    }

    return value;
  };

  const tooltipLabel = (value: string) => (
    <>
      <Text>Copy to clipboard</Text>
      <Text>{value}</Text>
    </>
  );

  const totalRow = props.totalRows ? ` / ${props.totalRows}` : "";
  const margin = props.inCard ? "0rem -1rem" : "";

  const position = props.inCard ? "absolute" : undefined;
  const right = props.inCard ? "0" : undefined;
  const top = props.inCard ? "-3rem" : undefined;

  const pagination = (
    <HStack
      mb="5px"
      justifyContent="flex-end"
      position={position}
      right={right}
      top={top}
    >
      <PaginationPrev
        ml="1rem"
        onClick={() => setStartIndex(Math.max(0, startIndex - perPage))}
      />
      <Text>{`${startIndex + 1} - ${Math.min(
        endIndex,
        props.rows.length
      )}${totalRow}`}</Text>
      <PaginationNext
        onClick={() => {
          if (props.loadMoreData && endIndex + perPage >= props.rows.length)
            props.loadMoreData();
          if (startIndex + perPage < props.rows.length)
            setStartIndex(startIndex + perPage);
        }}
      />
    </HStack>
  );

  // calculate column widths
  const maxLens = props.headers.map((_, i) =>
    Math.max(
      ...props.rows.slice(0, 30).map((r) => {
        // space needed for date
        if (moment.isMoment(r[i])) {
          return 10;
        }

        if (r[i].toString().length === 36) {
          return 15;
        }

        // column length should be at least 10 chars
        let len = r[i].toString().length;
        // icon present on entry
        if (getEntryIcon(r[i].toString())) {
          len += 3;
        }

        // restrict max length on mobile (uuid fields mostly)
        if (isMobile) {
          return Math.min(15, len);
        }

        return Math.min(len, props.columnMaxWidths?.[i] || len);
      })
    )
  );
  const lensSum = maxLens.reduce((a, b) => a + b, 0);
  const flexes = props.headers.map((_, i) => maxLens[i] / lensSum);
  const rows = props.rows.slice(startIndex, endIndex);

  // ensure table are readable and scrollable on mobile
  const minTableWidth = `${props.headers.length * 125}px`;

  // Callback with the current page number
  if (props.onPage) props.onPage(endIndex / perPage);

  return (
    <>
      {props.rows.length === 0 && !props.showEmpty ? (
        <>
          <NoContent
            halfCard={props.halfCard}
            title={props.noContentTitle || "No data available"}
            image="table"
            linkText={props.noContentLinkText || "Learn more about this data"}
            linkDestination={props.noContentLinkDestination || BrokerAPIDocSrc}
          />
        </>
      ) : (
        <>
          <Box position="relative">
            {!props.hidePagination && pagination}
            <TableContainer
              m={margin}
              $inCard={props.inCard}
              colormode={colorMode}
            >
              <Box minW={minTableWidth}>
                {(props.onDelete || props.onDownload) && !isMobile && (
                  <Box float="right">
                    <Box p="0.5rem 1rem" mt="1.5rem" />
                    {rows.map((_, i) => (
                      <RowItem
                        key={i}
                        $rRadius={true}
                        $inCard={props.inCard}
                        $active={itemHovered[0] === i}
                        $colormode={colorMode}
                        onMouseEnter={() => {
                          setItemHovered([i, -1]);
                          setConfirmDelete(false);
                        }}
                        onMouseLeave={() => setItemHovered([-1, -1])}
                        onClick={
                          props.buttonSkipClick
                            ? undefined
                            : () => clickRow(i, -1)
                        }
                      >
                        <Box
                          visibility={
                            itemHovered[0] === i ? "visible" : "hidden"
                          }
                        >
                          {confirmDelete ? (
                            <>
                              <Text display="inline">Are you sure?</Text>
                              <CloseIcon
                                boxSize="0.8em"
                                m="0px 5px"
                                _hover={{ color: "red.500" }}
                                onClick={() => setConfirmDelete(false)}
                              />
                              <CheckIcon
                                m="0px 5px"
                                _hover={{ color: "green.500" }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  if (props.onDelete) props.onDelete(i);
                                  setConfirmDelete(false);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {props.onDelete && (
                                <DeleteIcon
                                  _hover={{ color: "red.500" }}
                                  onClick={() => setConfirmDelete(true)}
                                />
                              )}
                              {props.onDownload && (
                                <DownloadIcon
                                  _hover={{ color: "blue.300" }}
                                  onClick={() =>
                                    props.onDownload && props.onDownload(i)
                                  }
                                />
                              )}
                            </>
                          )}
                        </Box>
                      </RowItem>
                    ))}
                  </Box>
                )}
                <Box>
                  <Flex>
                    {props.headers.map((header, i) => (
                      <Box
                        p="0.5rem 1rem"
                        color="grey"
                        key={i}
                        flex={flexes[i]}
                      >
                        <Text float={rJustify(i) ? "right" : undefined}>
                          {header}
                        </Text>
                      </Box>
                    ))}
                  </Flex>
                  {rows.map((row, i) => (
                    <Flex key={i}>
                      {row.map((value, j) => (
                        <RowItem
                          flex={flexes[j]}
                          $lRadius={j === 0}
                          $rRadius={
                            j === row.length - 1 &&
                            !props.onDelete &&
                            !props.onDownload
                          }
                          isTruncated
                          key={j}
                          $inCard={props.inCard}
                          $active={itemHovered[0] === i}
                          $canCopy={props.copyIndexes?.includes(j)}
                          onMouseEnter={() => {
                            if (value === "-" || value === "" || props.disabled)
                              return;
                            setItemHovered([i, j]);
                            setConfirmDelete(false);
                          }}
                          onMouseLeave={() => setItemHovered([-1, -1])}
                          onClick={() => {
                            if (props.disabled) return;
                            clickRow(i, j);
                          }}
                          color={itemColor(value.toString())}
                        >
                          {showCopyIcon(i, j) && (
                            <Tooltip
                              label={tooltipLabel(value.toString())}
                              placement="top"
                            >
                              <CopyIcon
                                mr="4px"
                                onClick={(e) => copyValue(e)}
                              />
                            </Tooltip>
                          )}
                          {getEntryIcon(value.toString())}
                          {formatValue(value, j)}
                        </RowItem>
                      ))}
                    </Flex>
                  ))}
                </Box>
              </Box>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

Table.defaultProps = {
  inCard: false,
  buttonSkipClick: false,
  rightJustifyIndexes: [],
};

export default Table;
