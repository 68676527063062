import moment from "moment";
import Tab from "../components/general/Tab";
import UUID from "../components/general/UUID";
import Header from "../components/layout/Header";
import AddUser from "../components/user/AddUser";
import ExportToCsv from "../components/tables/ExportButton";

import React, { useEffect, useMemo, useState, useRef } from "react";

import {
  capitalize,
  fmtLCTMoney,
  getAccountStatusColor,
  formatDate,
} from "../globals/utils";

import {
  FilterDrawer,
  FilterDateSelect,
  FilterSelect,
  FilterString,
  FilterButton,
  RangeType,
  dateRangeToPill,
} from "../components/filter";

import {
  Tabs,
  TabList,
  TabPanels,
  Flex,
  Spacer,
  useToast,
  Input,
  Text,
  Box,
  Link,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import { Column, Cell } from "react-table";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Account, AccountsParams, TradingAccount } from "../api/types";
import { getAccounts, getTradingAccount } from "../api/api";
import { Table, TableProps } from "@alpacahq/alpaca-component-library";
import { EventType, getAmplitude } from "../globals/amplitude";

interface FormData {
  status: string;
  accountID: string;
  accountName: string;
  accountNumber: string;
  email: string;
  timeframe: string;
  selectedDates: RangeType;
}

const inactiveAccounts = [
  "SUBMITTED",
  "ACTION_REQUIRED",
  "APPROVAL_PENDING",
  "APPROVED",
  "ACCOUNT_CLOSED",
  "REJECTED",
  "INACTIVE",
  "ONBOARDING",
  "SUBMISSION_FAILED",
];

const statusOptions = ["All", "ACTIVE", "ACCOUNT_UPDATED", ...inactiveAccounts];

const defaultFilters: FormData = {
  status: "All",
  accountID: "",
  accountName: "",
  accountNumber: "",
  email: "",
  timeframe: "anytime",
  selectedDates: [null, null],
};

const Users = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const fetchRefId = useRef(0);
  const skipPageResetRef = useRef(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [addUserOpen, setAddUserOpen] = useState(false);

  const { isOpen, onToggle } = useDisclosure();
  const [filters, setFilters] = useState<FormData>(defaultFilters);
  const [appliedFilters, setAppliedFilters] = useState<FormData>(
    defaultFilters
  );

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);

  // Fetch brokerage accounts
  const brokerAccountsResponse = useQuery<Account[], unknown>(
    ["accounts", appliedFilters],
    () => {
      const params: AccountsParams = {
        status: appliedFilters.status,
        id: appliedFilters.accountID,
        account_number: appliedFilters.accountNumber,
        query: [appliedFilters.accountName, appliedFilters.email]
          .filter((e) => e.trim() !== "")
          .join(","),
      };

      if (appliedFilters.selectedDates[0] && appliedFilters.selectedDates[1]) {
        params.created_after = appliedFilters.selectedDates[0]
          .startOf("day")
          .toISOString();
        params.created_before = appliedFilters.selectedDates[1]
          .endOf("day")
          .toISOString();
      }

      return getAccounts(params);
    },
    {
      onSuccess: (data) => {
        if (!Object.keys(appliedFilters).length) {
          setAllAccounts(data);
        }
      },
    }
  );

  // return a filtered list of accounts
  const brokerageAccounts = useMemo(() => {
    const data = brokerAccountsResponse.data ?? [];

    // filter by status if applicable
    switch (selectedTab) {
      case 1:
        return data.filter((account) => account.status === "ACTIVE");
      case 2:
        return data.filter((account) =>
          inactiveAccounts.includes(account.status)
        );
    }

    return data;
  }, [selectedTab, brokerAccountsResponse.data]);

  const brokerageAccountIDs = useMemo(
    () => brokerageAccounts.map((acct) => acct.id),
    [brokerageAccounts]
  );

  const tradingAccountsResponse = useQuery(
    ["trading-accounts", brokerageAccountIDs, page, pageSize],
    () =>
      Promise.all(
        brokerageAccounts
          ?.filter(
            (_, index) =>
              // If index is greater than or equal to page start index
              index >= (page - 1) * pageSize &&
              // If index is less than page end index
              index < page * pageSize
          )
          .map((account) => getTradingAccount(account.id))
      ),
    {
      select: (tradingAccounts) => {
        return tradingAccounts.reduce(
          (prev, acct) => ({
            ...prev,
            [acct.id]: acct,
          }),
          {} as Record<string, TradingAccount>
        );
      },
    }
  );

  useEffect(() => {
    if (brokerAccountsResponse.isError) {
      toast({
        title: "An error occurred fetching brokerage accounts",
        description: (brokerAccountsResponse.error as Error).message,
        status: "error",
      });
    }

    if (tradingAccountsResponse.isError) {
      toast({
        title: "An error occurred fetching trading accounts",
        description: (tradingAccountsResponse.error as Error).message,
        status: "error",
      });
    }
  }, [brokerAccountsResponse.isError, tradingAccountsResponse.isError]);

  useEffect(() => {
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_VISITED,
    });
  }, []);

  const columns: Column<Account>[] = [
    {
      Header: "Account Number",
      accessor: ({ id, account_number }) => (
        <UUID
          onClick={() => navigate(`/accounts/${id}`)}
          value={account_number}
        />
      ),
    },
    {
      Header: "Account ID",
      accessor: ({ id }) => {
        return (
          <UUID
            onClick={() => navigate(`/accounts/${id}`)}
            value={id}
            isTruncated
          />
        );
      },
    },
    { Header: "Name", accessor: "name" },
    {
      Header: "Equity",
      accessor: ({ id, currency }) =>
        fmtLCTMoney(tradingAccountsResponse.data?.[id]?.equity, currency),
    },
    {
      Header: "Last Equity",
      accessor: ({ id, currency }) =>
        fmtLCTMoney(tradingAccountsResponse.data?.[id]?.last_equity, currency),
    },
    {
      Header: "Address",
      Cell: ({ row: { original: account } }: Cell<Account>) => {
        const address = [
          ...(account?.contact?.street_address || []),
          account?.contact?.state,
          account?.contact?.postal_code,
          account?.identity?.country_of_tax_residence,
        ]
          .filter((field) => !!field)
          .join(", ");

        return (
          <Tooltip label={address} placement="top">
            <Text isTruncated maxWidth="300px">
              {address}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getAccountStatusColor(status)}>{capitalize(status)}</Text>
      ),
    },
    {
      Header: "Crypto Status",
      accessor: ({ crypto_status }) => (
        <Text color={getAccountStatusColor(crypto_status)}>
          {capitalize(crypto_status || "-")}
        </Text>
      ),
    },
    {
      Header: "Cash Interest Status",
      accessor: ({ cash_interest }) => (
        <Text color={getAccountStatusColor(cash_interest?.USD?.status)}>
          {capitalize(cash_interest?.USD?.status || "-")}
        </Text>
      ),
    },
    {
      Header: "APR Tier",
      accessor: ({ cash_interest }) => (
        <Text>{capitalize(cash_interest?.USD?.apr_tier_name || "-")}</Text>
      ),
    },
    {
      Header: "Created At",
      accessor: ({ created_at }) => {
        return (
          <Flex>
            <Text>{formatDate(created_at, "YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>
              &nbsp;{formatDate(created_at, "hh:mm A z")}
            </Text>
          </Flex>
        );
      },
    },
  ];

  const filterPills = {
    status: capitalize(appliedFilters.status),
    selectedDates: dateRangeToPill(appliedFilters.selectedDates),
    accountID: appliedFilters.accountID,
    accountName: appliedFilters.accountName,
    accountNumber: appliedFilters.accountNumber,
    email: appliedFilters.email,
  };

  const removeFilter = (filterKey: string) => {
    setAppliedFilters((filter) => ({
      ...filter,
      timeframe: filterKey === "selectedDates" ? "anytime" : filter.timeframe,
      [filterKey]: defaultFilters[filterKey as keyof FormData],
    }));
  };

  const onFetchData: TableProps<Account>["onFetchData"] = ({ pageIndex }) => {
    if (++fetchRefId.current !== fetchRefId.current) {
      return;
    }

    skipPageResetRef.current = true;

    setPage(pageIndex + 1);
    setPageSize(pageSize);
  };

  const tabSwitchAmplitudeEvent = (idx: number) => {
    if (idx > 0) {
      getAmplitude().track({
        event_type:
          idx === 1
            ? EventType.ACCOUNTS_PAGE_ACTIVE_USERS
            : EventType.ACCOUNTS_PAGE_INACTIVE_USERS,
      });
    }
  };

  const filterAccountsAmplitudeEvent = () => {
    if (appliedFilters.status !== "All") {
      getAmplitude().track({
        event_type: EventType.ACCOUNTS_PAGE_STATUS_FILTER,
      });
    }

    if (appliedFilters.selectedDates !== [null, null]) {
      getAmplitude().track({
        event_type: EventType.ACCOUNTS_PAGE_CREATED_AT_FILTER,
      });
    }
  };

  const onApply = () => {
    setAppliedFilters(filters);
    onToggle();
    filterAccountsAmplitudeEvent();
  };

  const onOpen = () => {
    setFilters(appliedFilters);
    onToggle();
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_FILTER,
    });
  };

  const onClose = () => {
    onToggle();
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_CANCEL_FILTER,
    });
  };

  const setFilterValue = (
    name: keyof FormData,
    value: FormData[typeof name]
  ) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const formatForExport = (data: Account[]) => {
    return data.map((account) => ({
      ...account,
      created_at: formatDate(account.created_at, "YYYY-MM-DDTHH:mm:ss.SSZ"),
    }));
  };

  return (
    <Box>
      <Header title="Accounts" />
      <Text>
        Learn more about our account opening process{" "}
        <Link
          href="https://alpaca.markets/docs/broker/integration/account-opening/"
          isExternal
        >
          here
        </Link>
      </Text>
      <FilterDrawer isOpen={isOpen} onClose={onClose} onApply={onApply}>
        <FilterSelect
          header="Status"
          options={statusOptions}
          onSelect={(value) => setFilterValue("status", value)}
          selected={filters.status}
        />
        <FilterDateSelect
          header="Created At"
          onSelect={(value) => setFilterValue("timeframe", value)}
          onDateSelect={(start, end) =>
            setFilterValue("selectedDates", [start, end])
          }
          defaultSelect={filters.timeframe}
          value={filters.selectedDates}
        />
        <FilterString
          header="Account ID"
          onChange={(value) => setFilterValue("accountID", value)}
          value={filters.accountID}
        />
        <FilterString
          header="Account Name"
          onChange={(value) => setFilterValue("accountName", value.trim())}
          value={filters.accountName}
        />
        <FilterString
          header="Account Number"
          onChange={(value) => setFilterValue("accountNumber", value)}
          value={filters.accountNumber}
        />
        <FilterString
          header="Email Address"
          onChange={(value) => setFilterValue("email", value)}
          value={filters.email}
        />
      </FilterDrawer>
      <AddUser isOpen={addUserOpen} onClose={() => setAddUserOpen(false)}>
        <Input variant="filled" type="email" placeholder="Email" />
      </AddUser>
      <Tabs
        onChange={(idx) => {
          setSelectedTab(idx);
          tabSwitchAmplitudeEvent(idx);
        }}
        mt="3rem"
      >
        <Flex>
          <TabList>
            <Tab text="All Users" isSelected={selectedTab === 0} />
            <Tab text="Active Users" isSelected={selectedTab === 1} />
            <Tab text="Inactive Users" isSelected={selectedTab === 2} />
          </TabList>
          <Spacer />
          <ExportToCsv
            data={formatForExport(brokerageAccounts)}
            allData={formatForExport(allAccounts)}
            amplitudeEvent={EventType.ACCOUNTS_PAGE_EXPORT}
          />
          <FilterButton
            filterPills={filterPills}
            openFilter={onOpen}
            removeFilter={removeFilter}
          />
        </Flex>
        <TabPanels>
          <Table
            columns={columns}
            data={brokerageAccounts}
            onFetchData={onFetchData}
            defaultPerPage={pageSize}
            pagination
            paginationPosition="top"
            skipPageResetRef={skipPageResetRef}
            isLoading={
              brokerAccountsResponse.isLoading ||
              tradingAccountsResponse.isLoading
            }
          />
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Users;
